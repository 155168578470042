<template>
  <van-form @submit="onSubmit">
    <div class="padding-top-15">
      <van-cell-group inset>
        <van-field
            required
            v-model="form.g_name"
            label="公司名称"
        />
        <van-field
            required
            label-width="100"
            v-model="form.g_company_code"
            label="社会统一代码"
        />
      </van-cell-group>
    </div>
    <div class="padding-top-15">
      <van-cell-group inset>
        <hang-ye label="行业" required v-model:name="form.g_hang_ye"/>
        <Address title="地址" required v-model:id="form.g_area_id"/>
        <van-field
            v-model="form.g_address"
            label="详细地址"
        />
      </van-cell-group>
    </div>

    <div class="form-btn">
      <van-button round block type="primary" native-type="submit">
        保存
      </van-button>
    </div>
  </van-form>
</template>

<script>
import AddImg from "../../../components/file/AddImg";
import http from "../../../api/http";
import XEUtils from "xe-utils";
import GongSiCaoZuo from "./gongSiCaoZuo";
import FuWuShangType from "../../../components/select/FuWuShangType";
import HangYe from "../../../components/select/hangYe";
import Address from "../../../components/select/Address";
import din_shi from "../../../api/din_shi";
import {updateUserInfo} from "@/api/cookie";
import qieHuanGongSi from "@/views/user/public/qieHuanGongSi";
import {setKeHu} from "@/api/dataList/userApi";

//预览服务商信息
export default {
  name: 'CompanyInfoEdit',
  components: {Address, HangYe, FuWuShangType, GongSiCaoZuo, AddImg},
  mixins: [],
  data() {
    return {
      form: {}
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      http.get('/gzhphp/user/getGongSiInfo').then(da => {
        if (da.code === 1) {
          if (!XEUtils.isNull(da.data)) {
            da.data.php_address = XEUtils.toString(da.data?.php_address?.name).join('/')
            this.form = da.data
          }
        }
      })
    },
    onSubmit() {
      http.post('/gzhphp/user/editGongSiInfo', this.form).then(da => {
        if (da.code === 1) {
          din_shi.success(da.msg).then(() => {
            setKeHu().then(() => {
              this.$router.back(-1)
            })
          })
        }
      })
    }
  },
  watch: {},
  computed: {}
}
</script>

<style scoped>

</style>
