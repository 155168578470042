<template>
  <van-field
      readonly
      :required="required"
      v-model="value"
      :label="label"
      :placeholder="placeholder"
      is-link
      @click="showSelect = true"
  />
  <van-popup
      v-model:show="showSelect"
      round
      position="bottom"
      :style="{ height: '300px' }"

  >
    <van-tree-select
        @click-item="clickItem"
        v-model:active-id="level_1"
        v-model:main-active-index="level_2"
        :items="items"
    />
  </van-popup>
</template>

<script>
//选择行业
import http from "../../api/http";
import XEUtils from "xe-utils";

/**
 * <hang-ye label="行业" v-model:name="form.g_hang_ye" />
 */
export default {
  name: 'hangYe',
  mixins: [],
  props: {
    required: Boolean,
    label: String,
    placeholder: {
      type: String,
      default() {
        return '请选择'
      }
    },
    name: String
  },
  data() {
    return {
      showSelect: false,
      items: [],
      level_1: 0,
      level_2: '',
      value: ''
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      http.get("/portal/index/getHangYw").then(da => {
        if (da.code === 1) {
          da.data = XEUtils.mapTree(da.data, n => {
            n.text = n.name
            return n
          })
          this.items = da.data
          this.jianSetName(this.name)
          this.$watch('name', this.jianSetName)
        }
      })
    },
    clickItem(item) {
      let parent = {}
      this.items.forEach(n => {
        if (item.parent_id == n.id) {
          parent = n
        }
      })
      let name = [parent.name, item.name].join('/')
      this.$emit('update:name', name)
      this.value = name
    },
    //坚挺数据改变
    jianSetName(newVal) {

      if (!XEUtils.isString(newVal)) return

      //根据名称最后一级的名称 获取对数据
      newVal = XEUtils.last(newVal.split('/'))
      let item = null
      XEUtils.eachTree(this.items, n => {
        if (n.name === newVal) {
          item = n;
        }
      })
      if (XEUtils.isObject(item)) {
        this.clickItem(item);
      }

    }
  },

  computed: {}
}
</script>


<style scoped>


</style>
