<template>
  <div class="gong-si-edit-btn">
    <router-link :to="{name:'JiaRuGongSi'}">关联其他企业</router-link><br/>
    <router-link :to="{name:'YuanGongGuanLi'}">员工管理</router-link><br/>
    <router-link :to="{name:'ZhuanYiFuZeRen'}">转移负责人</router-link><br/>
  </div>
</template>

<script>
export default {
  name: 'gongSiCaoZuo',
  mixins: [],
  mounted() {
  },
  methods: {},
  watch: {},
  computed: {}
}
</script>

<style scoped>
.gong-si-edit-btn{
  margin: 10px;
  line-height: 30px;
}
</style>
