<template>
  <div>
    <van-popup
        v-model:show="pageStatus"
        round
        position="bottom"
        :style="{ height: '300px' }"
    >
      <div class="lie-biao">
        <div class="an-niu">
          <router-link :to="{name:'JiaRuGongSi'}">关联其他公司</router-link>
        </div>
        <div class="gong-si">
          <div
              class="gong-si-item"
              :class="vo.className"
              v-for="vo in list"
              :key="vo.g_id"
              @click="setGongSi(vo)"
          >
            <div class="logo">
              <van-image round height="25" width="25" :src="vo.logo"/>
            </div>
            <div class="van-ellipsis g-name">
              <template v-if="vo.className">
                ({{ vo.sq_status }})
              </template>
              {{ vo.g_name }}
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import http from "../../../api/http";
import XEUtils from "xe-utils";
import {updateUserInfo} from "@/api/cookie";

export default {
  name: 'qieHuanGongSi',
  mixins: [],
  data() {
    return {
      pageStatus: false,
      list: []
    }
  },
  mounted() {
    this.getSuoShuGongSi()
  },
  methods: {
    show() {
      this.pageStatus = true
    },
    setGongSi(item) {
      if (item.sq_status !== '通过') return

      http.post("/gzhphp/user/setDangQianGongSi", {g_id: item.g_id}).then(da => {
        if (da.code === 1) {
          updateUserInfo().then(()=>{
            location.reload()
          })
        }
      })
    },
    getSuoShuGongSi() {
      http.get('/gzhphp/user/getSuoShuGongSi').then(da => {
        if (da.code === 1) {
          this.list = da.data.map(n => {
            if (n.sq_status !== '通过') {
              n.className = 'jing-yong'
            }
            return n
          })
        }
      })
    }
  },
  watch: {},
  computed: {}
}
</script>


<style scoped lang="less">
@import "../../../assets/basis_css/style/var";

.lie-biao {
  display: flex;
  padding: 10px;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: space-around;
  align-items: stretch;

  .an-niu {
    text-align: right;
    height: 40px;
    line-height: 40px;
  }

}

.gong-si {
  overflow-y: auto;
  height: 230px;

  .gong-si-item {
    display: flex;
    color: @blue;
    margin-top: 10px;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;

    .logo {
      width: 50px;
      text-align: center;
    }

    .g-name {
      flex: 1;
    }
  }
}

::v-deep .jing-yong {
  color: @gray-6 !important;
}

</style>
